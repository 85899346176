import { render, staticRenderFns } from "./CardDialogFinish.vue?vue&type=template&id=9b99d0d6&scoped=true&style=background%3A%20%23E5E5E5&"
import script from "./card-dialog.component.ts?vue&type=script&lang=ts&"
export * from "./card-dialog.component.ts?vue&type=script&lang=ts&"
import style0 from "./CardDialogFinish.vue?vue&type=style&index=0&id=9b99d0d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b99d0d6",
  null
  
)

export default component.exports