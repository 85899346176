<template>
  <v-container fluid class="my-3">
    <v-row>
      <v-col align="end" class="mr-5">
        <v-btn :disabled="loadingShippingBatches || (shippingBatches && shippingBatches.length === 0)" color="textPrimary" class="mr-5" outlined @click=getShippingBatches>
          <v-icon :class="hasAuthorityUploadMovementMenu ? 'mr-2' : ''">mdi-refresh</v-icon>
          Atualizar
        </v-btn>

        <v-btn v-if="hasAuthorityUploadMovementMenu" :disabled="loadingShippingBatches" color="textPrimary" class="white--text" @click="openBathExecuteModal">
          Executar Lote
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-data-table
          class="elevation-1"
          item-key="id"
          :headers="headers"
          :items="shippingBatches"
          :loading="loadingShippingBatches"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalPages"
          :footer-props="{ itemsPerPageOptions: [25, 50, 75, 100], disablePagination: loadingShippingBatches }"
          @update:options="changePage($event)"
          :height="shippingBatches && shippingBatches.length >= 10 ? 500 : null"
          fixed-header
          hide-default-header
        >
          <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
              <tr class="table-header">
                <th style="background-color: #d9d9d9 !important; text-align: start; white-space: nowrap;" v-for="header in props.headers" :key="header.text">
                  <label class="label primary--text">
                    {{ header.text }}
                  </label>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:no-data>
            <span>Nenhum dado encontrado.</span>
          </template>
          <template v-slot:[`header.changeStatus`]="{ header }">
            <span>{{ hasAuthorityBatchViewSend ? header.text : null }}</span>
          </template>
          <template v-slot:[`header.actions`]="{ header }">
            <span>{{ hasAuthorityBatchDownloadFile || hasAuthorityBatchInsertProtocolSend || hasAuthorityBatchViewMovement ? header.text : null }}</span>
          </template>
          <template v-slot:[`item.carrier`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.carrier ? item.carrier : '-' }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.createdAt ? formatter.formatDateTimeZoneWithHours(item.createdAt) : '-' }}
            </span>
          </template>
          <template v-slot:[`item.sendAt`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.sendAt ? formatter.formatDateTimeZoneWithHours(item.sendAt) : '-' }}
            </span>
          </template>
          <template v-slot:[`item.protocolNumber`]="{ item }">
            <v-row align="center" class="align-center mt-3">
              <v-col class="pa-0" cols="12" md="10" xl="8" style="white-space: nowrap;">
                <v-text-field
                  class="pt-0"
                  v-model="item.protocolNumber"
                  v-mask="'#################'"
                  placeholder="Informe"
                  dense
                  solo
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @blur="item.protocolNumber = formatter.removeInvalidNumber(item.protocolNumber);"
                  @change="insertBatchProtocol(item.id, item.protocolNumber)"
                  :rules="[rule.validatesNumberGreaterThanOrEqualToZero(item.protocolNumber)]"
                  color="textPrimary"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.updateLoginManualExecution`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.updateLoginManualExecution ? formatter.formatToTitleCase(item.updateLoginManualExecution) : '-' }}
            </span>
          </template>
          <template v-slot:[`item.responsable`]="{ item }">
            {{ item.responsable ? formatter.formatToTitleCase(item.responsable) : '-' }}
          </template>
          <template v-slot:[`item.batchType`]="{ item }">
            {{ item.batchType === 'FILE' ? 'Arquivo' : item.batchType === 'API' ? 'Api' : '-' }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span>{{ formatter.batchStatusFormatter(item.status) }}</span>
          </template>
          <template v-slot:[`item.hasError`]="{ item }">
            <div v-if="item.status === 'TRANSFORMATION_ERROR' || item.status === 'CONVERSION_ERROR'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn color="primary" icon @click="openMovementErrorModal(item, 'batch')">
                      <v-icon color="red" size="25"> fas fa-times </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span> Erro de Movimentação </span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-btn color="primary" icon disabled>
                <v-icon size="25"> fas fa-times </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-if="hasAuthorityBatchDownloadFile || hasAuthorityBatchInsertProtocolSend || hasAuthorityBatchViewMovement" v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="textPrimary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getActionsShippingBatchesForItem(item)" />
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
    <CancelMovementsModal
      ref="CancelMovementsModal"
      @filterMovementById="filterMovementById"
    />
    <MovementErrorModal
      ref="MovementErrorModal"
      :hasErrorReportRow="false"
      :movementError="propsMovementError"
      :insuredName="propsInsuredName"
      :insuranceCarrierIdWithError="insuranceCarrierIdWithError"
      :loadingTableMovementError="propsLoadingTableMovementError"
      :tabError="propsTabError"
      :statusMovement="propsStatusMovement"
      @redirectMovements="filterMovementById"
    />
    <BatchExecuteModal
      ref="BatchExecuteModal"
      :insuranceCarriers="insuranceCarriers"
    />

    <v-dialog v-model="changeStatusDialog.isOpen" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Atualizar Status </v-card-title>
        <v-card-text>
          Deseja marcar o arquivo como enviado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="textPrimary"
            text
            @click="changeStatusDialog.isOpen = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="textPrimary"
            outlined
            @click="updateBatchStatus(null, null)"
          >
            Marcar como enviado
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelBatchDialog.isOpen" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5"> Cancelar Lote </v-card-title>
        <v-card-text>
          Ao confirmar o cancelamento do lote, as movimentações retornarão para o status "Liberado" e não serão enviadas para a operadora até que um novo lote seja gerado. 
        </v-card-text>
        <v-card-text>          
          Tem certeza que deseja cancelar?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="textPrimary"
            text
            @click="cancelBatchDialog.isOpen = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="textPrimary"
            outlined
            @click="cancelBatch()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FileDataService from '@/services-http/sdi/FileDataService';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import CancelMovementsModal from '@/components/DashboardMovementRecords/Common/CancelMovementsModal.vue'; /* add na common depois */
import MovementErrorModal from '@/components/DashboardMovementRecords/Common/MovementErrorModal.vue';
import MovementErrorService from '@/services-http/sdi/MovementErrorsService';
import MovementInsuranceCarrierService from '@/services-http/sdi/MovementInsuranceCarrierService';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import { cloneDeep } from 'lodash';
import BatchExecuteModal from '@/components/Batch/BatchExecuteModal.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default ({
  name: 'ShippingBatchesTable',

  components: {
    TodoListActions, SnackbarCustomize, CancelMovementsModal, MovementErrorModal, BatchExecuteModal,
  },

  data: () => ({
    loadingShippingBatches: false,
    loadingInsertProtocol: false,
    hasAuthorityBatchViewSend: false,
    hasAuthorityBatchDownloadFile: false,
    hasAuthorityBatchInsertProtocolSend: false,
    hasAuthorityBatchViewMovement: false,
    hasAuthorityUploadMovementMenu: false,
    hasAuthoritySendByFile: false,
    hasAuthorityReprocess: false,
    hasAuthorityViewAllMovements: false,
    hasAuthorityViewEndMovementAction: false,
    hasAuthorityViewLogs: false,
    propsLoadingTableMovementError: false,
    isRHProtegido: false,
    createdComponent: true,

    page: 1,
    totalPages: 0,
    itemsPerPage: 25,
    options: {},
    propsStatusMovement: '',
    propsInsuredName: '',
    propsTabError: '',
    insuranceCarrierIdWithError: '',

    changeStatusDialog: {
      isOpen: false,
      id: '',
      protocolNumber: '',
      changeStatus: false,
      status: '',
    },
    cancelBatchDialog: {
      isOpen: false,
      id: '',
      protocolNumber: '',
      changeStatus: false,
      status: '',
    },
    propsMovementError: [],
    shippingBatches: [],
    headers: [
      {
        text: 'ID Lote',
        value: 'id',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Operadora',
        value: 'carrier',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Data de Criação',
        value: 'createdAt',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Data de Envio',
        value: 'sendAt',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Protocolo de Envio',
        value: 'protocolNumber',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Criado por',
        value: 'updateLoginManualExecution',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Responsável',
        value: 'responsable',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Tipo do Lote',
        value: 'batchType',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Erro',
        value: 'hasError',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
        class: 'text--truncate',
      },
    ],
    queryParams: {},
    onlyParameterFilter: {},
    filtersShippingBatches: {},
  }),

  mixins: [
    AuthorityManagementMixin,
    VerifyRoutesMixin,
  ],

  props: { filterShippingBatches: { type: Object }, insuranceCarriers: { type: Array }, resetFilterShippingBatches: { type: Boolean } },

  watch: {
    filterShippingBatches: {
      deep: true,
      async handler(value) {
        if (value && !this.resetFilterShippingBatches) {
          this.page = 1;
          this.filtersShippingBatches = await value;
          setTimeout(() => {
            this.getShippingBatches();
          }, 250);
        }
      },
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementErrorService = new MovementErrorService();
  },

  mounted() {
    this.verifyAuthorities();
  },

  methods: {
    verifyAuthorities() {
      this.hasAuthorityBatchViewSend = this.hasPermission('sdi_painel_movimentacoes_cadastrais_lote_enviar');
      this.hasAuthorityBatchDownloadFile = this.hasPermission('sdi_painel_movimentacoes_cadastrais_lote_download_arquivo');
      this.hasAuthorityBatchInsertProtocolSend = this.hasPermission('sdi_painel_movimentacoes_cadastrais_lote_inserir_protocolo_enviado');
      this.hasAuthorityBatchViewMovement = this.hasPermission('sdi_painel_movimentacoes_cadastrais_lote_visualizar_movimentacao');
      this.hasAuthorityUploadMovementMenu = this.hasPermission('sdi_upload_arquivo_movimentacao');
      this.hasAuthoritySendByFile = this.hasPermission('sdi_lote_enviar_por_arquivo');
      this.hasAuthorityReprocess = this.hasPermission('sdi_lote_reprocessar');
      this.hasAuthorityViewAllMovements = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_movimentacao');
      this.hasAuthorityViewEndMovementAction = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_acao_finalizar_movimentacao');
      this.hasAuthorityViewLogs = this.hasPermission('sdi_visualizacao_historico_movimentacoes');
    },
    async changePage(event) {
      if (sessionStorage.getItem('searchParams') && this.createdComponent) {
        this.filtersShippingBatches = await cloneDeep(JSON.parse(sessionStorage.getItem('onlyParameterFilter')));
        this.page = Number(JSON.parse(sessionStorage.getItem('searchParams')).page);
        this.createdComponent = false;
        this.itemsPerPage = event.itemsPerPage;
        await this.getShippingBatches();
      } else if (!this.createdComponent) {
        this.page = event.page;
        this.itemsPerPage = event.itemsPerPage;
        this.getShippingBatches();
      } else {
        this.createdComponent = false;
      }
    },
    async getShippingBatches() {
      this.loadingShippingBatches = true;
      this.createdComponent = false;
      const filteredParams = await this.getFilteredParams();
      const params = await this.buildSearchParams(filteredParams);
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      try {
        const response = await movementInsuranceCarrierService.FindByParams(params);

        if (this.responseIsValid(response)) {
          this.updateShippingBatches(response.data.content, response.data.totalElements);
        } else {
          this.shippingBatches = [];
          this.totalPages = 0;
        }
      } catch (error) {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os lotes');
      } finally {
        this.loadingShippingBatches = false;
        this.createdComponent = false;
      }
    },
    async getFilteredParams() {
      this.filtersShippingBatches.onlyManualProtocol = this.filtersShippingBatches.onlyManualProtocol || false;
      return Object.entries(this.filtersShippingBatches).filter(([key, value]) => value && (value !== '' && typeof value !== 'undefined')).reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
    },
    async buildSearchParams(filteredParams) {
      const formattedFilterParams = cloneDeep(filteredParams);

      await this.formatCreatedAtParams(formattedFilterParams);
      await this.formatSendAtParams(formattedFilterParams);
      this.onlyParameterFilter = cloneDeep(formattedFilterParams);

      const queryParams = {
        ...formattedFilterParams,
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: 'id,desc',
      };

      this.deleteParamsEmpty(queryParams);
      this.queryParams = cloneDeep(queryParams);

      return new URLSearchParams(queryParams);
    },
    deleteParamsEmpty(queryParams) {
      // eslint-disable-next-line no-param-reassign
      delete queryParams.dateType;
      // eslint-disable-next-line no-param-reassign
      delete queryParams.startDate;
      // eslint-disable-next-line no-param-reassign
      delete queryParams.endDate;
      if (queryParams.insuranceCarrierId && queryParams.insuranceCarrierId.length === 0) {
        // eslint-disable-next-line no-param-reassign
        delete queryParams.insuranceCarrierId;
      }
    },
    async formatCreatedAtParams(params) {
      if (params.dateType.includes('createdAt')) {
        // eslint-disable-next-line no-param-reassign
        params.createdAtStart = params.startDate;
        // eslint-disable-next-line no-param-reassign
        params.createdAtEnd = params.endDate;
      }
    },
    async formatSendAtParams(params) {
      if (params.dateType.includes('sendAt')) {
        // eslint-disable-next-line no-param-reassign
        params.sendAtStart = params.startDate;
        // eslint-disable-next-line no-param-reassign
        params.sendAtEnd = params.endDate;
      }
    },
    responseIsValid(response) {
      return response && response.data && response.data.content && response.data.content.length > 0;
    },
    updateShippingBatches(content, totalElements) {
      this.shippingBatches = content;
      this.totalPages = totalElements;
    },
    getActionsShippingBatchesForItem(item) {
      const actions = [
        {
          label: 'Enviar por Arquivo',
          icon: 'fas fa-file-import',
          color: 'textPrimary',
          condition: item.status === 'API_ERROR' && this.hasAuthoritySendByFile,
          disabled: item.fileDataId,
          handler: () => this.updateBatchStatus(item, 'SEND_BY_FILE'),
        },
        {
          label: 'Reprocessar',
          icon: 'fas fa-redo',
          color: 'textPrimary',
          condition: item.status === 'API_ERROR' && this.hasAuthorityReprocess,
          disabled: item.fileDataId,
          handler: () => this.updateBatchStatus(item, 'REPROCESS'),
        },
        {
          label: 'Download',
          icon: 'fas fa-download',
          color: 'textPrimary',
          condition: this.hasAuthorityBatchDownloadFile,
          disabled: !item.fileDataId,
          handler: () => this.downloadFile(item.fileDataId, item.fileName),
        },
        {
          label: 'Ver Movimentação',
          icon: 'fas fa-eye',
          color: 'textPrimary',
          condition: this.hasAuthorityViewAllMovements,
          handler: () => this.$emit('getMovementByMovementInsuranceCarrierId', item.id),
        },
        {
          label: 'Finalizar Movimentações',
          icon: 'mdi mdi-account-check',
          color: 'textPrimary',
          iconSize: '25',
          condition: this.conditionMovementFinish(item),
          handler: () => this.endMovementsByBlocks(item),
        },
        {
          label: 'Enviar Lote',
          icon: 'mdi mdi-upload',
          color: 'textPrimary',
          iconSize: '25',
          condition: this.hasAuthorityBatchViewSend,
          disabled: item.status !== 'FILE_GENERATED',
          handler: () => this.batchStatusChangeDialog(item),
        },
        {
          label: 'Cancelar Lote',
          icon: 'fas fa-ban ',
          color: 'textPrimary',
          iconSize: '25',
          condition: this.hasAuthorityBatchViewSend,
          disabled: item.status !== 'FILE_GENERATED',
          handler: () => this.batchCancelDialog(item),
        },
        {
          label: 'Visualizar Logs',
          icon: 'fas fa-database',
          color: 'textPrimary',
          condition: this.hasAuthorityViewLogs,
          handler: () => this.onClickViewLogs(item.id),
        },
      ];

      return actions;
    },
    conditionMovementFinish(item) {
      if (item && this.hasAuthorityViewEndMovementAction && item.carrierId !== '5299') {
        return true;
      }
      return false;
    },
    handleSubmittedStatus() {
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      const batch = {
        id: Number(this.changeStatusDialog.id),
        status: 'SUBMITTED',
      };
      movementInsuranceCarrierService.InsertProtocol(batch);
      this.getShippingBatches();
    },
    handleCancelBatch() {
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      
      movementInsuranceCarrierService.CancelBatch(this.cancelBatchDialog.id);
      this.getShippingBatches();
    },
    handleApiErrorStatusForReprocess(item) {
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      const batch = {
        id: Number(item.id),
        status: 'API_WAIT_REPROCESSING',
      };
      movementInsuranceCarrierService.InsertProtocol(batch);
      this.getShippingBatches();
    },
    handleApiErrorStatusForSendFile(item) {
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      const batch = {
        id: Number(item.id),
        status: 'WAIT_SEND_FILE',
      };
      movementInsuranceCarrierService.SendByFile(batch.id);
      this.getShippingBatches();
    },
    updateBatchStatus(item, action) {
      if (this.changeStatusDialog.status === 'FILE_GENERATED') {
        this.handleSubmittedStatus();
      } else if (this.changeStatusDialog.status === 'WAIT_FILE_GENERATION' || this.changeStatusDialog.status === 'FILE_GENERATION') {
        this.$refs.SnackbarCustomize.open('Não é possível marcar como enviado, pois o arquivo está em processamento.');
      } else if (this.changeStatusDialog.status === 'SUBMITTED') {
        this.$refs.SnackbarCustomize.open('Não é possível desmarcar como enviado!');
      } else if (item.status === 'API_ERROR') {
        if (action === 'REPROCESS') {
          this.handleApiErrorStatusForReprocess(item);
        } else if (action === 'SEND_BY_FILE') {
          this.handleApiErrorStatusForSendFile(item);
        }
      }

      this.changeStatusDialog.isOpen = false;
    },
    cancelBatch() {
      if (this.cancelBatchDialog.status === 'FILE_GENERATED') {
        this.handleCancelBatch();
      } 

      this.cancelBatchDialog.isOpen = false;
    },
    openMovementErrorModal(item, tabError) {
      if (item) {
        this.propsTabError = tabError;
        this.propsStatusMovement = item.status;
        if (this.propsStatusMovement !== 'TRANSFORMATION_ERROR') {
          this.propsLoadingTableMovementError = true;
          this.requestMovementErrors(item);
        } else {
          this.insuranceCarrierIdWithError = item.id;
          this.$refs.MovementErrorModal.openModal();
        }
      }
    },
    requestMovementErrors(item) {
      const query = `?movementInsuranceCarrierId=${item.id}`;
      this.movementErrorService.FindAllByFilters(query).then(async (response) => {
        if (response && response.data && response.data.length > 0) {
          this.propsMovementError = await response.data;
        }
        this.propsLoadingTableMovementError = false;
        this.$refs.MovementErrorModal.openModal();
      }).catch(() => {
        this.propsLoadingTableMovementError = false;
      });
    },
    downloadFile(id, filename) {
      const fileDataService = new FileDataService();

      fileDataService.downloadFile(id).then((response) => {
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${filename}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
      });
    },
    async insertBatchProtocol(id, protocolNumber) {
      const movementInsuranceCarrierService = new MovementInsuranceCarrierService();
      this.loadingInsertProtocol = true;
      // eslint-disable-next-line no-param-reassign
      protocolNumber = this.formatter.removeInvalidNumber(protocolNumber);
      if (String(protocolNumber) !== '0') {
        const batch = {
          id: Number(id),
          protocol: protocolNumber ? String(protocolNumber) : '',
        };
        await movementInsuranceCarrierService.InsertProtocol(batch).then(() => {
          this.loadingInsertProtocol = false;
          this.getShippingBatches();
        }).catch(() => {
          this.loadingInsertProtocol = false;
        });
      }
    },
    endMovementsByBlocks(item) {
      const props = {
        ...item,
        isEndMovemnts: true,
      };
      this.$refs.CancelMovementsModal.open(props);
    },
    batchStatusChangeDialog(item) {
      if (item.status === 'FILE_GENERATED') {
        this.changeStatusDialog.id = item.id;
        this.changeStatusDialog.protocolNumber = item.protocolNumber;
        this.changeStatusDialog.changeStatus = item.changeStatus;
        this.changeStatusDialog.status = item.status;
        this.changeStatusDialog.isOpen = true;
      }
    },
    batchCancelDialog(item) {
      if (item.status === 'FILE_GENERATED') {
        this.cancelBatchDialog.id = item.id;
        this.cancelBatchDialog.protocolNumber = item.protocolNumber;
        this.cancelBatchDialog.changeStatus = item.changeStatus;
        this.cancelBatchDialog.status = item.status;
        this.cancelBatchDialog.isOpen = true;
      }
    },
    onClickViewLogs(id) {
      this.setQueryForRedirection();
      this.redirectRouter('HistoryLog', { isRHProtegido: this.isRHProtegido }, { type: 'batch', id });
    },
    async filterMovementById(id, status) {
      this.$emit('getMovementByMovementInsuranceCarrierId', id, status);
    },
    openBathExecuteModal() {
      this.$refs.BatchExecuteModal.open();
    },
    setQueryForRedirection() {
      const dateType = this.adjustedDateInShippingBatches();

      const selectedDate = sessionStorage.getItem('selectedDate');

      const queryParams = {
        ...this.queryParams,
        page: this.page,
        tab: 'shippingBatches',
        dateType,
        selectedDate,
      };

      sessionStorage.setItem('searchParams', JSON.stringify(queryParams));
      sessionStorage.setItem('onlyParameterFilter', JSON.stringify(this.onlyParameterFilter));
    },
    adjustedDateInShippingBatches() {
      const validDateKeys = ['createdAtStart', 'sendAtStart'];
      const dateTypeValidate = validDateKeys.filter((key) => this.queryParams[key]);
      const bothPresent = dateTypeValidate.includes('createdAtStart') && dateTypeValidate.includes('sendAtStart');
      const onlyCreatedAtPresent = !bothPresent && dateTypeValidate.includes('createdAtStart');
      const onlySendAtPresent = !bothPresent && !onlyCreatedAtPresent && dateTypeValidate.includes('sendAtStart');

      let dateTypeAux = [];
      if (bothPresent) {
        dateTypeAux = ['createdAt', 'sendAt'];
      }
      if (onlyCreatedAtPresent) {
        dateTypeAux = ['createdAt'];
      }
      if (onlySendAtPresent) {
        dateTypeAux = ['sendAt'];
      }

      return dateTypeAux;
    },
  },
});
</script>
