<template>
  <v-container fluid class="mb-5">
    <v-row>
      <v-col align="end" class="mt-3 mr-5">
        <v-btn :disabled="loadingFileMovements || (fileMovements && fileMovements.length === 0)" color="textPrimary" class="mr-5" outlined @click=getFileMovements()>
          <v-icon :class="hasAuthorityUploadMovementMenu ? 'mr-2' : ''">mdi-refresh</v-icon>
          Atualizar
        </v-btn>

        <v-btn v-if="hasAuthorityUploadMovementMenu" color="textPrimary" class="white--text" @click="openModalUploadMovement">
          <v-icon class="mr-2">mdi-file-upload</v-icon>
          Novo Upload
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-data-table
          class="elevation-1"
          item-key="id"
          :headers="headers"
          :items="fileMovements"
          :loading="loadingFileMovements"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalPages"
          :footer-props="{ itemsPerPageOptions: [25, 50, 75, 100], disablePagination: loadingFileMovements }"
          :height="fileMovements && fileMovements.length >= 10 ? 528 : null"
          @update:options="changePage($event)"
          fixed-header
          hide-default-header
        >
          <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
              <tr class="table-header">
                <th style="background-color: #d9d9d9 !important; text-align: start;" v-for="header in props.headers" :key="header.text">
                  <label class="label primary--text">
                    {{ header.text }}
                  </label>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:no-data>
            <span>Nenhum dado encontrado.</span>
          </template>
          <template v-slot:[`item.createdBy`]="{ item }">
            {{ item.createdBy ? formatter.formatToTitleCase(item.createdBy) : '-' }}
          </template>
          <template v-slot:[`item.fileName`]="{ item }">
            {{ item.fileName ? item.fileName : '-' }}
          </template>
          <template v-slot:[`item.receivingDate`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.receivingDate ? formatter.formatDateTimeZoneWithHours(item.receivingDate) : '-' }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="text--truncate">{{ item.status ? formatter.statusFormatterFileMovements(item.status) : '-' }}</span>
          </template>
          <template v-slot:[`item.totalElements`]="{ item }">
            <span class="text--truncate">{{ item.totalElements ? item.totalElements : '-' }}</span>
          </template>
          <template v-slot:[`item.processedElements`]="{ item }">
            <span class="text--truncate">{{ item.processedElements ? item.processedElements : '-' }}</span>
          </template>
          <template v-slot:[`item.unprocessedElements`]="{ item }">
            <span class="text--truncate">{{ item.unprocessedElements ? item.unprocessedElements : '-' }}</span>
          </template>
          <template v-slot:[`item.hasError`]="{ item }">
            <div v-if="item.hasError">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn color="primary" icon @click="openMovementErrorModal(item, 'protocol')">
                      <v-icon color="red" size="25"> fas fa-times </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span> Erro de Movimentação </span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-btn color="primary" icon disabled>
                <v-icon size="25"> fas fa-times </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="textPrimary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="countDocumentsByFileDataId(item);"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getActionsFileMovementForItem(item)" />
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
    <CancelMovementsModal
      ref="CancelMovementsModal"
      @filterMovementById="filterMovementById"
    />
    <UploadMovementsModal
      ref="UploadMovementsModal"
      @verifyCountDocuments="verifyCountDocuments"
    />
    <MovementErrorModal
      ref="MovementErrorModal"
      :movementError="propsMovementError"
      :insuredName="propsInsuredName"
      :loadingTableMovementError="propsLoadingTableMovementError"
      :tabError="propsTabError"
      :statusMovement="propsStatusMovement"
      @redirectMovements="filterMovementById"
    />
    <MovementUploadModal
      ref="MovementUploadModal"
      :financialGroupsProps="financialGroups"
    />
  </v-container>
</template>

<script>
import FileDataService from '@/services-http/sdi/FileDataService';
import Formatters from '@/shared/formatters/formatters';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import DocumentService from '@/services-http/sdi/DocumentService';
import CancelMovementsModal from '@/components/DashboardMovementRecords/Common/CancelMovementsModal.vue'; /* add na common depois */
import UploadMovementsModal from '@/components/Beneficiary/Documents/UploadDocumentsModal.vue'; /* add na common depois */
import MovementErrorModal from '@/components/DashboardMovementRecords/Common/MovementErrorModal.vue';
import MovementErrorService from '@/services-http/sdi/MovementErrorsService';
import Rules from '@/shared/validators/formRules';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import MovementUploadModal from '@/components/DashboardMovementRecords/Common/MovementUploadModal.vue';

export default ({
  name: 'FileMovementsTable',

  components: {
    TodoListActions, SnackbarCustomize, CancelMovementsModal, UploadMovementsModal, MovementErrorModal, MovementUploadModal,
  },

  data: () => ({
    loadingFileMovements: false,
    loadingCountDocuments: false,
    hasAuthorityUploadMovementMenu: false,
    propsLoadingTableMovementError: false,
    createdComponent: true,

    page: 1,
    totalPages: 0,
    itemsPerPage: 25,
    options: {},
    filterDocuments: {
      fileDataId: null,
      countDocuments: 0,
      documents: [],
    },
    propsStatusMovement: '',
    propsInsuredName: '',
    propsTabError: '',
    insuranceCarrierIdWithError: '',

    propsMovementError: [],
    fileMovements: [],
    headers: [
      {
        text: 'Protocolo',
        value: 'protocolNumber',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Nome do arquivo',
        value: 'fileName',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Criado por',
        value: 'createdBy',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Recebimento',
        value: 'receivingDate',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroup',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Total de Registros',
        value: 'totalElements',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Processados',
        value: 'processedElements',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Não Processados',
        value: 'unprocessedElements',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Erro',
        value: 'hasError',
        align: 'start',
        class: 'text--truncate',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
        class: 'text--truncate',
      },
    ],
  }),

  mixins: [
    AuthorityManagementMixin,
  ],

  props: { filterFileMovements: { type: Object }, financialGroups: { type: Array }, resetFilterFileMovements: { type: Boolean } },

  watch: {
    filterFileMovements: {
      deep: true,
      handler(value) {
        if (value && !this.resetFilterFileMovements) {
          this.page = 1;
          setTimeout(() => {
            this.getFileMovements();
          }, 250);
        }
      },
    },
  },

  created() {
    this.rule = new Rules();
    this.initializeServices();
  },

  mounted() {
    this.hasAuthorityUploadMovementMenu = this.hasPermission('sdi_upload_arquivo_movimentacao');
  },

  methods: {
    initializeServices() {
      this.fileDataService = new FileDataService();
      this.formatter = new Formatters();
      this.documentService = new DocumentService();
      this.movementErrorService = new MovementErrorService();
    },
    changePage(event) {
      if (!this.createdComponent) {
        this.itemsPerPage = event.itemsPerPage;
        this.page = event.page;
        this.getFileMovements();
      } else {
        this.createdComponent = false;
      }
    },
    async getFileMovements() {
      if (this.verifyParameterNumber(this.filterFileMovements.protocolNumber)) {
        this.loadingFileMovements = true;
        const filteredParams = this.getFilteredParams();
        const params = this.buildSearchParams(filteredParams);
        try {
          const response = await this.fileDataService.FindByParams(params);

          if (this.responseIsValid(response)) {
            this.updateFileMovements(response.data.content, response.data.totalElements);
          } else {
            this.fileMovements = [];
            this.totalPages = 0;
          }
        } catch (error) {
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar as movimentações via arquivo');
        } finally {
          this.loadingFileMovements = false;
        }
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Preencha os campos corretamente', 4000);
      }
    },
    getFilteredParams() {
      this.filterFileMovements.onlyFtpIntegration = this.filterFileMovements.onlyFtpIntegration || false;
      return Object.fromEntries(Object.entries(this.filterFileMovements).filter(([key, value]) => value && (value !== '' && value !== null && typeof value !== 'undefined')));
    },
    buildSearchParams(filteredParams) {
      return new URLSearchParams({
        ...filteredParams,
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: 'id,desc',
      });
    },
    verifyParameterNumber(parameter) {
      if (String(parameter)) {
        if (parameter <= 0) {
          return false;
        }
        return true;
      }
      return true;
    },
    responseIsValid(response) {
      return response && response.data && response.data.content && response.data.content.length > 0;
    },
    updateFileMovements(content, totalElements) {
      this.fileMovements = content;
      this.totalPages = totalElements;
    },
    getActionsFileMovementForItem(item) {
      const userPermissions = {
        download: 'sdi_painel_movimentacoes_cadastrais_protocolo_download_arquivo',
        viewMovements: 'sdi_painel_movimentacoes_cadastrais_protocolo_visualizar_movimentacao',
        viewAllMovements: 'sdi_painel_movimentacoes_cadastrais_visualizar_movimentacao',
        upload: 'sdi_painel_movimentacoes_cadastrais_protocolo_upload_documentos',
        cancelMovement: 'sdi_painel_movimentacoes_cadastrais_protocolo_cancelar_movimentacoes',
      };

      const actions = [
        {
          label: 'Download',
          icon: 'fas fa-download',
          color: 'textPrimary',
          condition: this.hasPermission(userPermissions.download),
          disabled: this.verifyFileWasUploaded(item.statusFile),
          handler: () => this.downloadFile(item.id, item.fileName),
        },
        {
          label: 'Ver movimentações',
          icon: 'fas fa-eye',
          color: 'textPrimary',
          condition: this.hasAllPermissions([userPermissions.viewMovements, userPermissions.viewAllMovements]),
          disabled: this.verifyFileWasUploaded(item.statusFile),
          handler: () => this.$emit('getMovementByProtocolId', item.protocolNumber),
        },
        {
          label: `Documentos ${!this.loadingCountDocuments && this.filterDocuments && this.filterDocuments.countDocuments ? `(${this.filterDocuments.countDocuments})` : !this.loadingCountDocuments ? '(0)' : ''}`,
          icon: 'fas fa-paperclip',
          color: 'textPrimary',
          loadingCountDocuments: this.loadingCountDocuments,
          condition: this.hasPermission(userPermissions.upload),
          disabled: this.loadingCountDocuments,
          handler: () => this.openModalToAttachDocuments(),
        },
        {
          label: 'Cancelar movimentações',
          icon: 'fas fa-ban',
          color: 'textPrimary',
          condition: this.hasAllPermissions([userPermissions.viewMovements, userPermissions.cancelMovement]) && this.verifyStatusFileProtocol(item.statusFile),
          handler: () => this.cancelMovementsByBlocks(item),
        },
      ];

      return actions;
    },
    verifyStatusFileProtocol(statusFile) {
      return (statusFile && statusFile === 'CONVERTED');
    },
    verifyFileWasUploaded(statusFile){
      return !statusFile;
    },
    openMovementErrorModal(item, tabError) {
      if (item) {
        this.propsTabError = tabError;
        this.propsStatusMovement = item.status;
        if (this.propsStatusMovement !== 'TRANSFORMATION_ERROR') {
          this.propsLoadingTableMovementError = true;
          this.requestMovementErrors(item);
        } else {
          this.insuranceCarrierIdWithError = item.id;
          this.$refs.MovementErrorModal.openModal();
        }
      }
    },
    requestMovementErrors(item) {
      const query = `?fileDataId=${item.id}`;
      this.movementErrorService.FindAllByFilters(query).then(async (response) => {
        if (response && response.data && response.data.length > 0) {
          this.propsMovementError = await response.data;
        }
        this.propsLoadingTableMovementError = false;
        this.$refs.MovementErrorModal.openModal();
      }).catch(() => {
        this.propsLoadingTableMovementError = false;
      });
    },
    downloadFile(id, filename) {
      this.fileDataService.downloadFile(id).then((response) => {
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${filename}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
      });
    },
    async countDocumentsByFileDataId(item) {
      if(!item.protocolNumber){
        this.loadingCountDocuments = false;
      }else{ 
        var fileDataId = item.protocolNumber;
        if ((fileDataId && this.filterDocuments && (this.filterDocuments.fileDataId === null || this.filterDocuments.fileDataId !== fileDataId)) || (this.countUploadOrDeleteDocuments !== this.filterDocuments.countDocuments)) {
          this.loadingCountDocuments = true;
          this.filterDocuments.fileDataId = null;
          this.filterDocuments.countDocuments = 0;

          const queryString = `?fileDataId=${fileDataId}`;
          await this.documentService.CountDocumentByFilters(queryString).then((response) => {
            this.filterDocuments.fileDataId = fileDataId;
            if (response) {
              this.filterDocuments.countDocuments = response.data;
              this.countUploadOrDeleteDocuments = response.data;
            }
          }).catch(() => {
            this.loadingCountDocuments = false;
            this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os documentos');
          }).finally(() => {
            this.loadingCountDocuments = false;
          });
        }
      }
    },
    async openModalToAttachDocuments() {
      if (this.filterDocuments && this.filterDocuments.fileDataId) {
        this.loadingDocuments = true;
        const queryString = `?fileDataId=${this.filterDocuments.fileDataId}`;
        await this.documentService.FindDocumentByFilters(queryString).then((response) => {
          if (response && response.data) {
            this.filterDocuments.documents = response.data;
            this.$refs.UploadMovementsModal.open(this.filterDocuments, 'PROTOCOL');
          }
        }).catch(() => {
          this.loadingDocuments = false;
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os documentos');
        }).finally(() => {
          this.loadingDocuments = false;
        });
      }
    },
    verifyCountDocuments(event) {
      this.countUploadOrDeleteDocuments = event;
    },
    cancelMovementsByBlocks(item) {
      const props = {
        ...item,
        isCancelMovements: true,
      };
      this.$refs.CancelMovementsModal.open(props);
    },
    async filterMovementById(id, status) {
      this.$emit('getMovementByProtocolId', id, status);
    },
    openModalUploadMovement() {
      this.$refs.MovementUploadModal.open();
    },
  },
});
</script>
