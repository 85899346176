import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MovementInsuranceCarrierReturnService from '@/services-http/sdi/MovementInsuranceCarrierReturnService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import Formatters from "@/shared/formatters/formatters";
import Rules from "@/shared/validators/formRules";

@Component
export default class CardDialog extends Vue {
  @Prop()
  show!: boolean;

  @Prop()
  movementSelecteds!: object;

  @Prop()
  readonly benefits!: any[];

  beneficiaries: any[] = [];

  codeMessage = '';

  isContitionForFamilyCode = false;

  isFreeMovement = false;

  snackbar = {
    show: false,
    type: '',
    text: '',
  };

  lengthFamilyCode = '#';

  dialogHeaders = [
    {
      text: 'Beneficiário',
      value: 'name',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Titular',
      value: 'holderName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Tipo Movimentação',
      value: 'movementType',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Tipo Beneficiário',
      value: 'beneficiaryType',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Operadora',
      value: 'insuranceCarrierName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Apólice / Subcontrato',
      value: 'contractName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Data de vigência solicitada',
      value: 'startDate',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Data de vigência efetivada',
      value: 'sendStartDate',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Nº Carteirinha',
      value: 'cardNumber',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Código Familiar',
      value: 'familyCode',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Sequencial',
      value: 'familyCodeSequential',
      align: 'left',
      sortable: false,
    },
  ];

  dialog = { show: false }

  movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();

  movementRecordService = new MovementRecordService();

  formatter = new Formatters();

  rules = new Rules();

  registerCards() {
    let beneficiaryCardsToRegister: any[] = [];

    beneficiaryCardsToRegister = this.beneficiaries.map((beneficiary) => ({
      returnType: beneficiary && beneficiary.returnType ? beneficiary.returnType : null,
      cardNumber: beneficiary && beneficiary.cardNumber ? beneficiary.cardNumber : '',
      movementInsuranceCarrierId: beneficiary && beneficiary.movementInsuranceCarrierId ? beneficiary.movementInsuranceCarrierId : null,
      movementRecordId: beneficiary && beneficiary.movementRecordId ? beneficiary.movementRecordId : null,
      familyCode: beneficiary && beneficiary.familyCode ? beneficiary.familyCode : null,
      familyCodeSequential: beneficiary && beneficiary.familyCodeSequential ? beneficiary.familyCodeSequential : null,
      sendStartDate: beneficiary && beneficiary.sendStartDate ? this.formatter.formatDateBRtoString(beneficiary.sendStartDate) : null,
    }));

    if (beneficiaryCardsToRegister.some((element: any) => (Object.values(element).some((value) => value === '')))) {
      this.customizeSnackbarMessage('error', 'Por favor, preencha os campos para prosseguir.');
    } else {
      this.$emit('on-confirm', true);

      this.movementInsuranceCarrierReturnService.Save(beneficiaryCardsToRegister, this.isFreeMovement).then(() => {
        this.customizeSnackbarMessage('success', 'Carteirinhas cadastradas com sucesso.');
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar cadastrar as carteirinhas.');
      });
    }
  }

  formatBenefitType(benefitType: string) {
    let benefitName = '-';
    if (this.benefits && this.benefits.length > 0) {
      const benefitFound = this.benefits.find((benefit) => benefit.xipp_contract_record_type_code === benefitType);
      benefitName = benefitFound && benefitFound.name ? benefitFound.name : '-';
    }
    return benefitName;
  }
  benefitData(item : any) {
    const { policy = '-', subContract = '-', benefitType } = item || {};
    const formattedBenefitType = benefitType ? `${this.formatBenefitType(benefitType)}` : '-';

    const policyValue = policy !== '-' ? `${policy} / ` : '- ';
    const subContractValue = subContract !== '-' ? `${subContract}` : '-';
    const formattedBenefitTypeValue = formattedBenefitType !== '-' ? `${formattedBenefitType} - ` : '';

    return `${formattedBenefitTypeValue}${policyValue}${subContractValue}`;
  }

  customizeSnackbarMessage(type: string, text: string) {
    this.snackbar.show = true;
    this.snackbar.type = type;
    this.snackbar.text = text;
  }

  created() {
    const hash = '#';
    this.lengthFamilyCode = hash.repeat(50);
    this.dialog.show = this.show;
  }

  @Watch('show', { immediate: true, deep: true })
  onDisplayModalChange(val: boolean) {
    this.dialog.show = val;
  }

  @Watch('movementSelecteds', { immediate: true, deep: true })
  onMovementSelectedsChange(val: any[]) {
    if (val && val.length > 0) {
      this.beneficiaries = val.map((beneficiary, index) => ({
        key: index,
        insuredName: beneficiary.insuredName,
        holderName: beneficiary.holderName,
        cardNumber: beneficiary.beneficiaryCard ? beneficiary.beneficiaryCard : null,
        movementType: beneficiary.movementType,
        contract: beneficiary.contractId,
        insuranceCarrierName: beneficiary.insuranceCarrier,
        contractName: this.benefitData(beneficiary),
        subcontractName: beneficiary.subcontractName,
        subcontract: beneficiary.subContract,
        beneficiaryType: beneficiary.beneficiaryType,
        startDate: beneficiary.startDate,
        sendStartDate: beneficiary.startDate ? this.formatter.formatDate(new Date(beneficiary.startDate)) : null,
        movementInsuranceCarrierId: beneficiary && beneficiary.movementInsuranceCarrierId ? beneficiary.movementInsuranceCarrierId : null,
        returnType: 'SUCCESS',
        movementRecordId: beneficiary && beneficiary.id ? beneficiary.id : null,
        familyCode: beneficiary.familyCode ? beneficiary.familyCode : '',
        familyCodeSequential: beneficiary.familyCodeSequential ? beneficiary.familyCodeSequential : '',
      }));
      this.isFreeMovement = val.some((element: any) => element.freeMovement);
    } else {
      this.beneficiaries = [];
    }
  }
}
