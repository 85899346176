<template>
  <v-row>
    <v-col cols="12" align="end">
      <v-btn color="textPrimary" class="mr-5" outlined @click=fetchFavoriteFilters()>
        <v-icon class="mr-2">mdi-refresh</v-icon>
        Atualizar Favoritos
      </v-btn>
      <v-btn outlined color="primary" @click="openSaveFilterModal()">
        <v-icon class="mr-2" color="primary" style="font-size: 21px">
          far fa-bookmark
        </v-icon>
        Salvar a pesquisa atual
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-data-table
        class="elevation-1"
        item-key="id"
        :headers="headers"
        :items="favoriteFilters"
        :loading="loadingFavoriteFilters"
        :items-per-page="5"
      >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.title ? item.title : '-' }}
      </template>
      <template v-slot:[`item.userName`]="{ item }">
        {{ item.userName ? item.userName : '-' }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt ? formatter.formatDateTimeZoneWithHours(item.createdAt) : '-' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn text small color="primary" @click="viewFilter(item)" :disabled="loadingFavoriteFilters" >
            <v-icon color="primary" style="font-size: 21px">fas fa-search</v-icon>
          </v-btn>
          <v-btn small text color="error" @click="deleteFilter(item)" :disabled="loadingFavoriteFilters">
            <v-icon color="red" style="font-size: 21px">fas fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    </v-col>

    <ModalCleanSlot ref="ModalCleanSlot" :widthModal="700" :heightCard="800">
      <div slot="card">
        <v-card-text style="justify-content: space-between;">
          <label class="secondary--text text-subtitle-1" style="white-space: pre-line; font-style: italic;">
            O seu filtro possui critérios dinâmicos de data, podendo alterar os dados na sua próxima pesquisa.
            Se desejar salvar um período fixo, opte por um intervalo personalizado.
          </label>
        </v-card-text>

        <v-card-actions class="mx-2 pb-5" style="justify-content: space-between;">
          <v-btn slot="cancelButton" outlined color="primary" @click="closeNotificationModal()">
            <v-icon class="mr-2">fa-solid fa-angle-left</v-icon>
            <span>Voltar</span>
          </v-btn>
          <v-btn slot="confirmButton" color="primary" @click="saveFavoriteFilters()" :loading="loadingSaveButton">
            <v-icon left>fas fa-check</v-icon>
            <span>Salvar assim mesmo</span>
          </v-btn>
        </v-card-actions>
      </div>
    </ModalCleanSlot>

    <ExclusionDynamicModal :show="deleteFavoriteFilter.show" :loadingConfirm="loadingConfirm" @close="closeDeleteFavoriteFilter" @confirm="confirmDeleteFavoriteFilter">
      <template slot="header"> Excluir Favorito </template>
      <template slot="content">
        Tem certeza que deseja excluir este filtro?
      </template>
    </ExclusionDynamicModal>

    <SaveFilterModal ref="SaveFilterModal" @saveNewFavoriteFilter="saveNewFavoriteFilter" :loadingSaveButton="loadingSaveButton" />

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-row>
</template>

<script>
import FavoriteFilterService from '@/services-http/sdi/FavoriteFilterService';
import Formatters from '@/shared/formatters/formatters';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import ModalCleanSlot from '@/components/Modals/ModalCleanSlot.vue';
import SaveFilterModal from '@/components/FavoriteSearchFilters/SaveFilterModal.vue';

export default ({
  name: 'FavoriteSearchComponent',

  components: {
    SnackbarCustomize,
    ExclusionDynamicModal,
    ModalCleanSlot,
    SaveFilterModal,
  },

  data: () => ({
    loadingFavoriteFilters: false,
    loadingSaveButton: false,
    loadingConfirm: false,
    favoriteTitle: null,
    selectedDateTable: null,
    favoriteFilters: [],

    deleteFavoriteFilter: {
      show: false,
      id: null,
    },
    dateType: [],
    dates: [],

    headers: [
      {
        text: 'Nome',
        value: 'title',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Proprietário',
        value: 'userName',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Criado',
        value: 'createdAt',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
    ],
  }),

  props: {
    filterBy: {
      type: String,
      default: '',
    },
    searchFilter: {
      type: Object,
      default: () => ({}),
    },
    changeTabValue: {
      type: String,
      default: '',
    },
  },

  created() {
    this.favoriteFilterService = new FavoriteFilterService();
    this.formatter = new Formatters();
    this.fetchFavoriteFilters();
  },

  methods: {
    openSaveFilterModal() {
      this.$refs.SaveFilterModal.open();
    },
    closeSaveFilterModal() {
      this.$refs.SaveFilterModal.close();
    },
    viewFilter(item) {
      this.$emit('loadThisFilter', item);
    },
    deleteFilter(item) {
      this.deleteFavoriteFilter.id = item.id;
      this.deleteFavoriteFilter.show = true;
    },
    fetchFavoriteFilters() {
      this.loadingFavoriteFilters = true;
      const context = this.changeTabValue;
      this.favoriteFilterService.FindByContext(context).then((response) => {
        this.favoriteFilters = response.data;
      }).catch((error) => {
        console.error('Error fetching favorite filters:', error);
        this.$refs.SnackbarCustomize.open('error', 'Erro ao buscar filtros favoritos');
      }).finally(() => {
        this.loadingFavoriteFilters = false;
      });
    },
    closeDeleteFavoriteFilter() {
      this.deleteFavoriteFilter.show = false;
      this.loadingConfirm = false;
    },
    confirmDeleteFavoriteFilter() {
      this.loadingConfirm = true;
      this.favoriteFilterService.Delete(this.deleteFavoriteFilter.id).then(this.handleDeleteSuccess).catch(this.handleDeleteError).finally(() => {
        this.closeDeleteFavoriteFilter();
        this.fetchFavoriteFilters();
      });
    },
    saveFavoriteFilters() {
      this.closeNotificationModal();
      this.loadingSaveButton = true;

      const query = this.buildQueryObject();
      query.queryParams = this.filterEmptyValues(query.queryParams);
      this.transformArraysAndNumbersToStrings(query);

      this.favoriteFilterService.Save(query).then(this.handleSaveSuccess).catch(this.handleSaveError).finally(() => {
        this.loadingSaveButton = false;
        this.closeSaveFilterModal();
      });
    },
    transformArraysAndNumbersToStrings(data) {
      const queryParamsKeys = Object.keys(data.queryParams);

      queryParamsKeys.forEach((key) => {
        if (Array.isArray(data.queryParams[key])) {
          data.queryParams[key] = data.queryParams[key].map((item) => String(item)).join(',');
        } else if (typeof data.queryParams[key] === 'number') {
          data.queryParams[key] = String(data.queryParams[key]);
        }
      });
    },
    handleSaveSuccess() {
      this.closeSaveFilterModal();
      this.fetchFavoriteFilters();
      this.$refs.SnackbarCustomize.open('success', 'Pesquisa salva com sucesso');
    },
    handleSaveError() {
      this.$refs.SnackbarCustomize.open('error', 'Erro ao cadastrar novo filtro como favorito');
    },
    saveNewFavoriteFilter(title) {
      this.deleteDates();
      this.favoriteTitle = title;
      this.retrieveSearchData();

      if (this.selectedDateTable !== 'custom') {
        this.deleteDates();
        this.openNotificationModal();
        return;
      }

      this.setSearchFilterDates();
      this.saveFavoriteFilters();
    },
    deleteDates() {
      delete this.searchFilter.receivingDateStart;
      delete this.searchFilter.receivingDateEnd;
      delete this.searchFilter.createdAtStart;
      delete this.searchFilter.createdAtEnd;
      delete this.searchFilter.sendAtStart;
      delete this.searchFilter.sendAtEnd;
    },
    retrieveSearchData() {
      this.selectedDateTable = sessionStorage.getItem('selectedDate');
      if (sessionStorage.getItem('datesSearch')) {
        this.dateType = JSON.parse(sessionStorage.getItem('datesSearch')).dateType;
      }
    },
    setSearchFilterDates() {
      const { dates } = JSON.parse(sessionStorage.getItem('datesSearch')) || {};
      switch (this.filterBy) {
        case 'FileMovementsContentFilter':
          this.setMovementsFilterDates(dates);
          break;
        case 'MovementsContentFilter':
          this.validateTypeDate();
          this.setMovementsFilterDates(dates);
          break;
        case 'ShippingBatcheContentFilter':
          this.setShippingBatchFilterDates(dates);
          break;
        default:
          console.error('Unexpected filter type:', this.filterBy);
          break;
      }
    },
    validateTypeDate() {
      if (this.dateType.includes('isMovementDate')) {
        this.searchFilter.isMovementDate = true;
      }
      if (this.dateType.includes('isCarrierSendDate')) {
        this.searchFilter.isCarrierSendDate = true;
      }
      if (this.dateType.includes('isCarrierFinishedDate')) {
        this.searchFilter.isCarrierFinishedDate = true;
      }
      if (this.dateType.includes('isValidityDate')) {
        this.searchFilter.isValidityDate = true;
      }
    },
    setMovementsFilterDates(dates) {
      this.searchFilter.receivingDateStart = `${dates[0]}T00:00:00.000`;
      this.searchFilter.receivingDateEnd = `${dates[1]}T23:59:59.999`;
    },
    setShippingBatchFilterDates(dates) {
      if (this.dateType.includes('createdAt') && !this.dateType.includes('sendAt')) {
        this.searchFilter.createdAtStart = `${dates[0]}T00:00:00.000`;
        this.searchFilter.createdAtEnd = `${dates[1]}T23:59:59.999`;
      }
      if (this.dateType.includes('sendAt') && !this.dateType.includes('createdAt')) {
        this.searchFilter.sendAtStart = `${dates[0]}T00:00:00.000`;
        this.searchFilter.sendAtEnd = `${dates[1]}T23:59:59.999`;
      }
      if (this.dateType.includes('createdAt') && this.dateType.includes('sendAt')) {
        this.searchFilter.createdAtStart = `${dates[0]}T00:00:00.000`;
        this.searchFilter.createdAtEnd = `${dates[1]}T23:59:59.999`;
        this.searchFilter.sendAtStart = `${dates[0]}T00:00:00.000`;
        this.searchFilter.sendAtEnd = `${dates[1]}T23:59:59.999`;
      }
    },
    openNotificationModal() {
      this.$refs.ModalCleanSlot.open();
    },
    closeNotificationModal() {
      this.$refs.ModalCleanSlot.close();
    },
    buildQueryObject() {
      return {
        title: this.favoriteTitle,
        context: this.changeTabValue,
        queryParams: {
          ...this.searchFilter,
          selectedDate: this.selectedDateTable,
        },
      };
    },
    filterEmptyValues(data) {
      return Object.fromEntries(Object.entries(data).filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value && (value !== '' && value !== null && typeof value !== 'undefined');
      }));
    },
    handleDeleteSuccess() {
      this.closeDeleteFavoriteFilter();
      this.$refs.SnackbarCustomize.open('success', 'Pesquisa deletada com sucesso');
    },
    handleDeleteError() {
      this.closeDeleteFavoriteFilter();
      this.$refs.SnackbarCustomize.open('error', 'Erro ao deletar esta pesquisa. Tente novamente');
    },
  },
});
</script>

<style>
.clickable {
  transition: background-color 0.3s;
  border-radius: 50%;
}

.clickable:hover {
  background-color: #eff2f3;
}
</style>
